import React from 'react';


const DownTime: React.FunctionComponent = () => {
  
  return (
    <>
      <h2>Vi vender snart tilbage!</h2>
      <h3>Vi opdaterer lånevilkår - beklager evt. ulemper det måtte have.</h3>
    </>
  );
};

export default DownTime;
