import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "typeface-red-hat-display";


Sentry.init({
  dsn: "https://b50c2cbf836446ada2114e3b3d22629d@o55209.ingest.sentry.io/5727273",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0,
  autoSessionTracking: false,
});


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
