import React, { useEffect, useState } from "react";
import { Element } from "react-scroll";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import AmountStep from "./ApplicationForm/AmountStep";
import PeriodOfRepaymentStep from "./ApplicationForm/PeriodOfRepaymentStep";
import ContactInfoStep from "./ApplicationForm/ContactInfoStep";
import { ApplicationFormStateContextProvider } from "./ApplicationForm/state";
import CoApplicantStep from "./ApplicationForm/CoApplicantStep";
import MaritalStatusStep from "./ApplicationForm/MaritalStatusStep";
import HasCoApplicantStep from "./ApplicationForm/HasCoApplicantStep";
import AccomodationTypeStep from "./ApplicationForm/AccomodationTypeStep";
import HabitationYearStep from "./ApplicationForm/HabitationYearStep";
import PersonalInformationStep from "./ApplicationForm/PersonalInformationStep";
import CitizenshipNonDanishStep from "./ApplicationForm/CitizenshipNonDanishStep";
import MemberOfAkasseStep from "./ApplicationForm/MemberOfAkasseStep";
import MonthlyAmountAfterExpensesStep from "./ApplicationForm/MonthlyAmountAfterExpensesStep";
import CardsStep from "./ApplicationForm/CardsStep";
import AlternativesStep from "./ApplicationForm/AlternativesStep";
import ChildrenStep from "./ApplicationForm/ChildrenStep";
import StatusStep from "./ApplicationForm/StatusStep";
import StepIndicator from "./ApplicationForm/components/StepIndicator";
import ProductIndicator from "./ApplicationForm/components/ProductIndicator";
import ConditionsStep from "./ApplicationForm/ConditionsStep";
import OtherAmountStep from "./ApplicationForm/OtherAmountStep";
import CitizenshipStep from "./ApplicationForm/CitizenshipStep";
import DropOutStep from "./ApplicationForm/DropOutStep";
import ProductBox from "./ApplicationForm/components/ProductBox";
import CookieConsent from "react-cookie-consent";
import CookiePage from "./ApplicationForm/CookiePage";
import ContinueApplicationStep from "./ApplicationForm/ContinueApplicationStep";
import { Footer } from "./ApplicationForm/components/Footer";
import ProductPage from "./ApplicationForm/ProductPage";
import TaskStatuses from "./ApplicationForm/admin/TaskStatuses";
import AdminLogin from "./ApplicationForm/admin/AdminLogin";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminApplicationSearch from "./ApplicationForm/admin/AdminApplicationSearch";
import DownTime from "./ApplicationForm/DownTime";

function App() {
  const [isInIframe, setIsInIframe] = useState<boolean>(false);

  const inIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  useEffect(() => {
    setIsInIframe(inIframe());
  }, [setIsInIframe]);

  return (
    <Router>
      <ApplicationFormStateContextProvider>
        <>
          <ToastContainer position="top-center" />
          <div className="bg-green-800 min-h-screen flex flex-col">
            {!isInIframe && (
              <div className="bg-gray-50 px-10 py-6 border-b border-gray-300">
                <div className="flex flex-row max-w-6xl mx-auto">
                  <div className="flex-1">
                    <a href="/">
                      <img
                        src="/images/bilhandel.svg"
                        className="h-6"
                        alt="bilhandel billån"
                      />
                    </a>
                  </div>
                  <div className="flex-1 text-right">
                    <span className="text-xs text-gray-900">
                      <span className="hidden sm:inline-block">
                        Finansieringen tilbydes af
                      </span>
                      <img
                        alt="resursbank"
                        src="/images/resurs-logo-blue.svg"
                        className="ml-4 h-6 inline-block"
                      />
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div
              className="flex-grow pb-20 px-2 "
              style={{ backgroundColor: "#E0EFF2" }}
            >
              <Switch>
                {/* ADMIN  */}
                <Route path="/admin">
                  <div className="flex justify-around">
                    <div
                      className="w-4/5 px-2 py-4 sm:px-5 sm:py-7 md:mx-auto border-white rounded mt-12 shadow-md"
                      style={{ backgroundColor: "white" }}
                    >
                      <Switch>
                        <Route exact path="/admin/taskstatuses">
                          <TaskStatuses />
                        </Route>
                        <Route exact path="/admin/login">
                          <AdminLogin />
                        </Route>
                        <Route exact path="/admin/search">
                          <AdminApplicationSearch />
                        </Route>
                      </Switch>
                    </div>
                  </div>
                </Route>
                {/* NORMAL  */}
                <Route>
                  <div>
                    <h1 className="px-2 py-4 sm:px-5 sm:py-7 text-4xl md:text-6xl text-gray-900 text-center font-semibold">
                      Købsbevis til din nye bil
                    </h1>
                    <div className="text-base md:text-lg text-gray-700 text-center mx-2 sm:mx-5">
                      <span className="block sm:inline-block whitespace-nowrap">
                        <img
                          src="/images/check-circle-alternate.svg"
                          alt="tick"
                          className="inline-block mb-1"
                        />{" "}
                        Ingen udbetaling eller pant i bilen
                      </span>
                      <span className="block sm:inline-block whitespace-nowrap">
                        <img
                          src="/images/check-circle-alternate.svg"
                          alt="tick"
                          className="inline-block mb-1 ml-2"
                        />{" "}
                        Kredit op til 300.000 kr.
                      </span>
                      <span className="block sm:inline-block whitespace-nowrap">
                        <img
                          src="/images/check-circle-alternate.svg"
                          alt="tick"
                          className="inline-block mb-1 ml-2"
                        />{" "}
                        Få svar med det samme
                      </span>
                    </div>
                    <Element name="top-of-content"></Element>
                    <div
                      className="px-2 py-4 sm:px-5 sm:py-7 md:max-w-2xl md:mx-auto border-white rounded mt-12 shadow-md"
                      style={{ backgroundColor: "#F3FAFA" }}
                    >
                      <ProductIndicator />
                      <StepIndicator />

                      
                      
                      <Switch>
                        <Route exact path="/">
                          <DownTime />
                          {/* <AmountStep /> */}
                        </Route>
                        <Route exact path="/partner/:partner">
                          <DownTime />
                          {/* <AmountStep /> */}
                        </Route>
                        <Route exact path="/andet-beloeb">
                          <OtherAmountStep />
                        </Route>
                        <Route path="/produkt/:amount?">
                          <DownTime />
                          {/* <PeriodOfRepaymentStep /> */}
                        </Route>
                        <Route path="/kunde">
                          <ContactInfoStep />
                        </Route>
                        <Route path="/civilstatus">
                          <MaritalStatusStep />
                        </Route>
                        <Route path="/boern">
                          <ChildrenStep />
                        </Route>
                        <Route path="/tilfoej-medansoeger">
                          <HasCoApplicantStep />
                        </Route>
                        <Route path="/medansoeger">
                          <CoApplicantStep />
                        </Route>
                        <Route path="/boligform">
                          <AccomodationTypeStep />
                        </Route>
                        <Route path="/bolig-indflytning">
                          <HabitationYearStep />
                        </Route>
                        <Route path="/statsborgerskab">
                          <CitizenshipStep />
                        </Route>
                        <Route path="/personinformation">
                          <PersonalInformationStep />
                        </Route>
                        <Route path="/andet-statsborgerskab">
                          <CitizenshipNonDanishStep />
                        </Route>
                        <Route path="/a-kasse">
                          <MemberOfAkasseStep />
                        </Route>
                        <Route path="/raadighedsbeloeb">
                          <MonthlyAmountAfterExpensesStep />
                        </Route>
                        <Route path="/kreditkort">
                          <CardsStep />
                        </Route>
                        <Route path="/betingelser">
                          <ConditionsStep />
                        </Route>
                        <Route path="/andre-laan">
                          <AlternativesStep />
                        </Route>
                        <Route path="/status">
                          <StatusStep />
                        </Route>
                        <Route path="/afbryd">
                          <DropOutStep />
                        </Route>
                        <Route path="/privatlivspolitik">
                          <CookiePage />
                        </Route>
                        <Route path="/fortsaetteansoegning">
                          <ContinueApplicationStep />
                        </Route>
                        <Route path="/produktvalg/:paymentid">
                          <ProductPage />
                        </Route>
                      </Switch>
                    </div>
                  </div>

                  <ProductBox />
                </Route>
              </Switch>
            </div>

            <Footer />
          </div>
          <CookieConsent
            buttonText="Ok"
            buttonStyle={{
              backgroundColor: "#29A094",
              color: "#fff",
              borderRadius: "5px",
              width: "100px",
            }}
            contentStyle={{ margin: "0px" }}
          >
            <div className="text-xs text-left ml-4 mt-2  sm:text-right">
              Denne hjemmeside benytter cookies,{" "}
              <a href="/privatlivspolitik" className="underline">
                læs mere
              </a>
            </div>
          </CookieConsent>
        </>
      </ApplicationFormStateContextProvider>
    </Router>
  );
}

export default App;
